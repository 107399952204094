<template>
  <div>
    <!-- Form element -->
    <div @click="showModal">
      <slot name="element" v-bind="{ productFilter: value, productFilters: value }">
        <!-- Single -->
        <template v-if="!multiple">
          <div class="form-control h-auto">
            <div class="d-flex align-items-center justify-content-between gutter-x-sm">
              <div v-if="!value">
                {{ $t('shared.placeholders.select') }}
              </div>
              <div v-else class="flex-fill d-flex justify-content-between">
                {{ value.name }}
                <div>
                  <region-flag :code="value.group.region.code" />
                  {{ value.group.name }}
                </div>
              </div>
              <div>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
              </div>
            </div>
          </div>
        </template>

        <!-- Multiple -->
        <template v-else>
          <div class="form-control h-auto">
            <div class="d-flex align-items-center justify-content-between gutter-x-sm">
              <div v-if="value.length === 0">
                {{ $t('shared.placeholders.select') }}
              </div>
              <div v-else style="min-width: 0;">
                <div class="d-flex flex-wrap gutter-xs">
                  <div
                    v-for="productFilter in value"
                    :key="productFilter.id"
                    class="btn-group"
                  >
                    <div class="btn btn-light btn-xs text-left">
                      {{ productFilter.name }}
                    </div>
                    <div class="btn btn-light btn-xs px-2 d-flex align-items-center" @click.stop="unselectProductFilter(productFilter)">
                      <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
              </div>
            </div>
          </div>
        </template>
      </slot>
    </div>

    <!-- Modal -->
    <b-modal
      :id="`product-filter-modal-${_uid}`"
      size="xl"
      :title="$t('views.productFilterModalSelect.title')"
      hide-footer
      no-fade
      @shown="$refs.filterName.focus()">
      <!-- Filters -->
      <div class="custom-grid mb-3">
        <div>
          <label for="filter-name">{{ $t('attributes.productFilter.name') }}</label>
          <input
            v-model="filters.name"
            type="text"
            class="form-control"
            id="filter-name"
            ref="filterName"
            :placeholder="$t('attributes.productFilter.name')">
        </div>

        <div>
          <label for="filter-group">{{ $t('attributes.productFilter.group') }}</label>
          <group-modal-select v-model="filters.groups" multiple />
        </div>
      </div>

      <hr>

      <!-- Products -->
      <h6>{{ $tc('views.productFilterModalSelect.results', productFiltersCount) }}</h6>

      <div class="table-responsive">
        <table class="table table-hover" :aria-busy="productFiltersLoading">
          <thead>
            <tr>
              <th-sortable sort-key="id" class="table-col-shrink">{{ $t('attributes.productFilter.id') }}</th-sortable>
              <th-sortable sort-key="name">{{ $t('attributes.productFilter.name') }}</th-sortable>
              <th>{{ $t('attributes.productFilter.group') }}</th>
              <th class="table-col-shrink"></th>
            </tr>
          </thead>
          <tbody v-if="productFiltersError">
            <tr>
              <td :colspan="colspan" class="text-center alert-danger">
                <template v-if="productFiltersError.status === 403">
                  {{ $t('errors.unauthorized.manage.all') }}
                </template>
                <template v-else>
                  {{ $t('errors.internalServerError') }}
                </template>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!productFiltersLoading && productFilters.length === 0">
            <tr>
              <td :colspan="colspan" class="text-center alert-warning">
                {{ $t('shared.warnings.noProductFilter') }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <!-- Product filter rows -->
            <tr
              v-for="{ productFilter, selected } in productFiltersWithStatus"
              :key="productFilter.id"
              @click="productFilterClick(productFilter)"
              class="cursor-pointer"
              :class="{ 'bg-light': selected }"
            >
              <th>{{ productFilter.id }}</th>
              <td>{{ productFilter.name }}</td>
              <td>
                <region-flag :code="productFilter.group.region.code" />
                {{ productFilter.group.name }}
              </td>
              <td>
                <svg v-if="selected" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check-circle fa-w-16"><path fill="currentColor" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z" class=""></path></svg>
              </td>
            </tr>

            <!-- Loading -->
            <template v-if="productFiltersLoading">
              <tr>
                <td :colspan="colspan" class="text-center">
                  <md-spinner md-indeterminate></md-spinner>
                </td>
              </tr>
            </template>

            <!-- Next page -->
            <tr v-if="productFiltersHasNextPage && !productFiltersLoading">
              <td :colspan="colspan" class="bg-primary text-white cursor-pointer" @click="loadProductFiltersPage">
                {{ $t('views.productFilterModalSelect.loadMore') }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import MdSpinner from './MdSpinner.vue'
import RegionFlag from './RegionFlag.vue'
import GroupModalSelect from '../shared/GroupModalSelect.vue'
import { BModal, VBModal } from 'bootstrap-vue'
import ThSortable from '../shared/ThSortable.vue'
import debounce from 'lodash-es/debounce'

export default {
  name: 'ProductFilterModalSelect',
  inheritAttrs: false,
  components: { MdSpinner, RegionFlag, GroupModalSelect, BModal, ThSortable },
  directives: { 'b-modal': VBModal },
  props: {
    value: [Object, Array],
    disabled: Boolean,
    multiple: {
      type: Boolean,
      default: false
    },
    groupId: {
      type: Number,
      default: undefined
    },
    filterGroup: {
      type: Object,
      default: undefined
    },
    exceptProductFilterIds: {
      type: Array,
      default: () => []
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {
      modalInitialized: false,
      productFilters: [],
      productFiltersLoading: false,
      productFiltersError: null,
      productFiltersCount: 0,
      productFiltersPage: 1,
      productFiltersPerPage: 20,
      productFiltersHasNextPage: false,
      productFiltersAbortController: null,
      filters: {
        name: null,
        groups: []
      },
      colspan: 10
    }
  },
  computed: {
    // Product filters with selected status
    productFiltersWithStatus: function() {
      return this.productFilters.map(productFilter => {
        let selected
        if (!this.multiple) {
          selected = this.value && this.value.id === productFilter.id
        } else {
          selected = this.value.map(productFilter => productFilter.id).includes(productFilter.id)
        }

        return { productFilter, selected }
      })
    }
  },
  methods: {
    // Show modal
    showModal: function() {
      this.$root.$emit('bv::show::modal', `product-filter-modal-${this._uid}`)
      if (!this.modalInitialized) {
        this.modalInitialized = true
        this.loadProductFiltersPage()
      }
    },
    // Load product filters page
    loadProductFiltersPage: async function() {
      if (this.productFiltersAbortController) {
        this.productFiltersAbortController.abort()
      }
      this.productFiltersAbortController = new AbortController()

      this.productFiltersLoading = true
      this.productFiltersError = null

      const query = `query productFilterModalSelectProducts ($page: Int!, $perPage: Int!, $groupId: Int, $name: String, $groupIds: [Int!], $exceptProductFilterIds: [Int!], $sort: String, $sortDirection: String) {
        productFiltersPage(page: $page, perPage: $perPage, groupId: $groupId, name: $name, groupIds: $groupIds, exceptProductFilterIds: $exceptProductFilterIds, sort: $sort, sortDirection: $sortDirection) {
          nodesCount
          hasNextPage
          nodes {
            id
            name
            group {
              id
              name
              region {
                id
                code
              }
            }
          }
        }
      }`

      try {
        const res = await fetch('/graphql', {
          signal: this.productFiltersAbortController.signal,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({
            query,
            variables: {
              page: this.productFiltersPage,
              perPage: this.productFiltersPerPage,
              groupId: this.groupId,
              name: this.filters.name,
              groupIds: this.filters.groups.length > 0 ? this.filters.groups.map(group => group.id) : undefined,
              exceptProductFilterIds: this.exceptProductFilterIds,
              sort: this.$route.query.sort,
              sortDirection: this.$route.query.sort_direction
            }
          })
        })
        const json = await res.json()

        if (!res.ok) {
          this.productFiltersError = json.errors
        } else {
          this.productFilters = this.productFilters.concat(json.data.productFiltersPage.nodes)
          this.productFiltersCount = json.data.productFiltersPage.nodesCount
          this.productFiltersHasNextPage = json.data.productFiltersPage.hasNextPage
          if (this.productFiltersHasNextPage) this.productFiltersPage++
        }
      } catch (err) {
        this.error = err
      } finally {
        this.productFiltersLoading = false
      }
    },
    // Load product filters debounced
    loadProductFiltersPageDebounced: debounce(function() {
      this.productFilters = []
      this.productFiltersPage = 1
      this.loadProductFiltersPage()
    }, 200),
    // Product filter click
    productFilterClick: function(productFilter) {
      if (!this.multiple) {
        // Single: emit input & close
        this.$emit('input', productFilter)

        if (this.closeOnSelect) {
          this.$root.$emit('bv::hide::modal', `product-filter-modal-${this._uid}`)
        }
      } else {
        // Multiple: toggle product filter & emit input
        let productFilters
        const index = this.value.map(productFilter => productFilter.id).indexOf(productFilter.id)

        if (index === -1) {
          productFilters = this.value.concat(productFilter)
        } else {
          productFilters = this.value.slice()
          productFilters.splice(index, 1)
        }
        this.$emit('input', productFilters)
      }
    },
    // Unselect a product filter
    unselectProductFilter: function(productFilter) {
      const index = this.value.map(productFilter => productFilter.id).indexOf(productFilter.id)

      if (index === -1) {
        throw new Error(`Unselect product filter #${productFilter.id} failed`)
      } else {
        const productFilters = this.value.slice()
        productFilters.splice(index, 1)
        this.$emit('input', productFilters)
      }
    }
  },
  watch: {
    filters: {
      deep: true,
      handler: function() {
        this.loadProductFiltersPageDebounced()
      }
    },
    filterGroup: function(group) {
      this.filters.groups = [group]
    },
    exceptProductFilterIds: function() {
      this.loadProductFiltersPageDebounced()
    },
    $route: function() {
      this.loadProductFiltersPageDebounced()
    }
  }
}
</script>
