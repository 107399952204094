
<div>
  <!-- Form element -->
  <div @click="showModal">
    <slot name="element" v-bind="{ productFilter: value, productFilters: value }">
      <!-- Single -->
      <template v-if="!multiple">
        <div class="form-control h-auto">
          <div class="d-flex align-items-center justify-content-between gutter-x-sm">
            <div v-if="!value">
              {{ $t('shared.placeholders.select') }}
            </div>
            <div v-else class="flex-fill d-flex justify-content-between">
              {{ value.name }}
              <div>
                <region-flag :code="value.group.region.code" />
                {{ value.group.name }}
              </div>
            </div>
            <div>
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
            </div>
          </div>
        </div>
      </template>

      <!-- Multiple -->
      <template v-else>
        <div class="form-control h-auto">
          <div class="d-flex align-items-center justify-content-between gutter-x-sm">
            <div v-if="value.length === 0">
              {{ $t('shared.placeholders.select') }}
            </div>
            <div v-else style="min-width: 0;">
              <div class="d-flex flex-wrap gutter-xs">
                <div
                  v-for="productFilter in value"
                  :key="productFilter.id"
                  class="btn-group"
                >
                  <div class="btn btn-light btn-xs text-left">
                    {{ productFilter.name }}
                  </div>
                  <div class="btn btn-light btn-xs px-2 d-flex align-items-center" @click.stop="unselectProductFilter(productFilter)">
                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-caret-down fa-w-10"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" class=""></path></svg>
            </div>
          </div>
        </div>
      </template>
    </slot>
  </div>

  <!-- Modal -->
  <b-modal
    :id="`product-filter-modal-${_uid}`"
    size="xl"
    :title="$t('views.productFilterModalSelect.title')"
    hide-footer
    no-fade
    @shown="$refs.filterName.focus()">
    <!-- Filters -->
    <div class="custom-grid mb-3">
      <div>
        <label for="filter-name">{{ $t('attributes.productFilter.name') }}</label>
        <input
          v-model="filters.name"
          type="text"
          class="form-control"
          id="filter-name"
          ref="filterName"
          :placeholder="$t('attributes.productFilter.name')">
      </div>

      <div>
        <label for="filter-group">{{ $t('attributes.productFilter.group') }}</label>
        <group-modal-select v-model="filters.groups" multiple />
      </div>
    </div>

    <hr>

    <!-- Products -->
    <h6>{{ $tc('views.productFilterModalSelect.results', productFiltersCount) }}</h6>

    <div class="table-responsive">
      <table class="table table-hover" :aria-busy="productFiltersLoading">
        <thead>
          <tr>
            <th-sortable sort-key="id" class="table-col-shrink">{{ $t('attributes.productFilter.id') }}</th-sortable>
            <th-sortable sort-key="name">{{ $t('attributes.productFilter.name') }}</th-sortable>
            <th>{{ $t('attributes.productFilter.group') }}</th>
            <th class="table-col-shrink"></th>
          </tr>
        </thead>
        <tbody v-if="productFiltersError">
          <tr>
            <td :colspan="colspan" class="text-center alert-danger">
              <template v-if="productFiltersError.status === 403">
                {{ $t('errors.unauthorized.manage.all') }}
              </template>
              <template v-else>
                {{ $t('errors.internalServerError') }}
              </template>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="!productFiltersLoading && productFilters.length === 0">
          <tr>
            <td :colspan="colspan" class="text-center alert-warning">
              {{ $t('shared.warnings.noProductFilter') }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <!-- Product filter rows -->
          <tr
            v-for="{ productFilter, selected } in productFiltersWithStatus"
            :key="productFilter.id"
            @click="productFilterClick(productFilter)"
            class="cursor-pointer"
            :class="{ 'bg-light': selected }"
          >
            <th>{{ productFilter.id }}</th>
            <td>{{ productFilter.name }}</td>
            <td>
              <region-flag :code="productFilter.group.region.code" />
              {{ productFilter.group.name }}
            </td>
            <td>
              <svg v-if="selected" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check-circle fa-w-16"><path fill="currentColor" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z" class=""></path></svg>
            </td>
          </tr>

          <!-- Loading -->
          <template v-if="productFiltersLoading">
            <tr>
              <td :colspan="colspan" class="text-center">
                <md-spinner md-indeterminate></md-spinner>
              </td>
            </tr>
          </template>

          <!-- Next page -->
          <tr v-if="productFiltersHasNextPage && !productFiltersLoading">
            <td :colspan="colspan" class="bg-primary text-white cursor-pointer" @click="loadProductFiltersPage">
              {{ $t('views.productFilterModalSelect.loadMore') }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-modal>
</div>
