<template>
  <div class="d-flex">
    <!-- Navbar -->
    <nav class="navbar fixed-top navbar-light bg-white border-bottom justify-content-start flex-md-row flex-column flex-nowrap">
      <div class="navbar-left d-flex align-items-center justify-content-center">
        <c2b-logo class="navbar-logo" />
        <div class="navbar-app-name text-nowrap">
          BRANDS
          <sup v-if="env" class="navbar-app-environment text-danger">{{ env }}</sup>
        </div>
      </div>

      <div class="navbar-right w-100 d-flex align-items-center gutter-x justify-content-between">
        <div class="d-flex align-items-center">
          <!-- Menu toggle button (mobile) -->
          <span class="navbar-toggler-icon mr-3 d-md-none" @click="toggleSidebar"></span>

          <!-- Breadcrumb -->
          <div class="navbar-title">
            <div class="d-flex gutter-x-sm flex-wrap">
              <template v-if="customBreadcrumb">
                <div>{{ customBreadcrumb }}</div>
              </template>
              <template v-else v-for="(breadcrumbPart, index) in $route.meta.breadcrumb">
                <div :key="breadcrumbPart">{{ $t(breadcrumbPart) }}</div>
                <div :key="`${breadcrumbPart}-separator`" v-if="index !== $route.meta.breadcrumb.length - 1">
                  <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" class="svg-inline--fa fa-angle-right fa-w-6"><path fill="currentColor" d="M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z" class=""></path></svg>
                </div>
              </template>
            </div>
          </div>
        </div>

        <b-nav class="flex-nowrap">
          <!-- Admin dropdown -->
          <b-nav-item-dropdown v-if="userIsReallyAdmin" right no-caret menu-class="admin-dropdown-menu">
            <template v-slot:text>
              <span class="admin-dropdown-icon">
                👑
              </span>
              <span class="d-none d-lg-inline">
                <region-flag :code="group.region.code" />
                <template v-if="userIsInDemoMode">
                  {{ $t('adminDropdown.yourBrand') }}
                </template>
                <template v-else>
                  {{ group.name }}
                </template>

              </span>
            </template>

            <template v-if="userIsReallyAdmin && !userIsInDemoMode">
              <!-- Group -->
              <li role="presentation">
                <header id="dropdown-header-label" role="heading" class="dropdown-header">
                  {{ $t('adminDropdown.group') }}
                </header>
              </li>
              <li role="presentation">
                <span class="dropdown-item cursor-pointer">
                  <group-modal-select :value="group" @input="selectGroup" :custom-pages="true">
                    <template v-slot:element="{ group }">
                      <div class="d-flex gutter-x-sm justify-content-between align-items-center">
                        <span>
                          <region-flag :code="group.region.code" />
                          {{ group.name }}
                        </span>
                        <span>
                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="edit" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-edit fa-w-18"><path fill="currentColor" d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z" class=""></path></svg>
                        </span>
                      </div>
                    </template>
                  </group-modal-select>
                </span>
              </li>
              <li role="presentation" v-show="!groupIsUserGroup">
                <button
                  aria-describedby="dropdown-header-label"
                  role="menuitem"
                  type="button"
                  class="dropdown-item text-danger"
                  @click="resetGroup">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" class="svg-inline--fa fa-times fa-w-11"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" class=""></path></svg>
                  {{ $t('adminDropdown.reset') }}
                </button>
              </li>

              <!-- Roles -->
              <li role="presentation">
                <header id="dropdown-header-label" role="heading" class="dropdown-header">
                  {{ $t('adminDropdown.roles') }}
                </header>
              </li>
              <span class="dropdown-item-text" v-for="role in userRoles" :key="role">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" :id="`checkbox-${role}`" :checked="roles.includes(role)" @input="toggleRole(role)">
                  <label class="custom-control-label" :for="`checkbox-${role}`">{{ role }}</label>
                </div>
              </span>
            </template>

            <template v-if="userIsReallyAdmin">
              <!-- Demo mode -->
              <li role="presentation">
                <header id="dropdown-header-label" role="heading" class="dropdown-header">
                  {{ $t('adminDropdown.demo') }}
                </header>
              </li>
              <span class="dropdown-item-text">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="checkbox-demo-mode" v-model="demoModeEnabled" @click="CheckIfUserHasDemoGroup" @change="toggleDemoMode">
                  <label class="custom-control-label" for="checkbox-demo-mode">{{ $t('adminDropdown.enableDemoMode') }}</label>
                </div>
              </span>
            </template>
          </b-nav-item-dropdown>

          <!-- User dropdown -->
          <b-nav-item-dropdown right no-caret>
            <template v-slot:text>
              <div class="gutter-x-sm">
                <img :src="user.group.img.thumb.url" class="user-img">
                <span class="d-none d-sm-inline">{{ user.email }}</span>
              </div>
            </template>
            <span class="dropdown-item-text text-nowrap" v-if="user">
              <region-flag :code="user.group.region.code" />
              {{ user.group.name }}
            </span>
            <div class="dropdown-divider"></div>
            <router-link :to="{ name: 'selfEditUser', query: demoQuery }" class="dropdown-item">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cog" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-cog fa-fw"><path fill="currentColor" d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z" class=""></path></svg>
              {{ $t('userDropdown.settings') }}
            </router-link>
            <b-dropdown-item @click="logout">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sign-out" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-sign-out fa-fw"><path fill="currentColor" d="M180 448H96c-53 0-96-43-96-96V160c0-53 43-96 96-96h84c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H96c-17.7 0-32 14.3-32 32v192c0 17.7 14.3 32 32 32h84c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm117.9-303.1l77.6 71.1H184c-13.3 0-24 10.7-24 24v32c0 13.3 10.7 24 24 24h191.5l-77.6 71.1c-10.1 9.2-10.4 25-.8 34.7l21.9 21.9c9.3 9.3 24.5 9.4 33.9.1l152-150.8c9.5-9.4 9.5-24.7 0-34.1L353 88.3c-9.4-9.3-24.5-9.3-33.9.1l-21.9 21.9c-9.7 9.6-9.3 25.4.7 34.6z" class=""></path></svg>
              {{ $t('userDropdown.logout') }}
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-nav>
      </div>
    </nav>

    <!-- Sidebar backdrop for mobile -->
    <transition name="fade">
      <div v-if="showSidebar" class="sidebar-bg d-md-none" @click="hideSidebar" />
    </transition>

    <!-- Sidebar -->
    <div class="sidebar border-right" :class="showSidebar ? '' : 'sidebar-mobile-hidden'">
      <side-menu />
    </div>

    <!-- Main -->
    <main class="main">
      <!-- Content -->
      <div class="content">
        <transition name="fade" mode="out-in">
          <router-view :key="$route.path" /><!-- key attribute triggers component reload if the path changes -->
        </transition>
      </div>
    </main>

    <!-- NPS popin -->
    <nps-popin />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { BNav, BNavItemDropdown, BDropdownItem } from 'bootstrap-vue'
import C2bLogo from '../shared/C2bLogo.vue'
import RegionFlag from '../shared/RegionFlag.vue'
import SideMenu from './SideMenu.vue'
import GroupModalSelect from '../shared/GroupModalSelect.vue'
import NpsPopin from '../shared/NpsPopin.vue'
import upperFirst from 'lodash-es/upperFirst'
import lowerFirst from 'lodash-es/lowerFirst'

export default {
  components: { BNav, BNavItemDropdown, BDropdownItem, C2bLogo, RegionFlag, SideMenu, GroupModalSelect, NpsPopin },
  data: function() {
    return {
      railsEnv: import.meta.env.MODE,
      showSidebar: false, // applies to mobile screens only
      userRoles: ['client', 'admin', 'investor', 'news_reader', 'michelin', 'validations_reader', 'product_distribution_reader', 'estimations_reader'],
      demoModeEnabled: false,
      groupSelect: false,
      groupSuggestionsLoading: false,
      customBreadcrumb: null
    }
  },
  provide: function() {
    return {
      setCustomBreadcrumb: this.setCustomBreadcrumb
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      group: 'auth/group',
      roles: 'auth/roles'
    }),
    env: function() {
      switch (this.railsEnv) {
        case 'development':
          return 'dev'
        case 'staging':
          return 'staging'
        default:
          return null
      }
    },
    // User helpers
    userIsReallyAdmin: function() {
      return this.user.roles.includes('admin')
    },
    userIsInDemoMode: function() {
      return this.$route.query.demo === 'true'
    },
    // Check if group is same as user group
    groupIsUserGroup: function() {
      return this.group.id === this.user.group.id
    },
    demoQuery: function() {
      return this.$route.query.demo === 'true' ? { demo: 'true' } : {}
    }
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout'
    }),
    ...mapMutations({
      setGroup: 'auth/setGroup',
      toggleRole: 'auth/toggleRole'
    }),
    toggleSidebar: function() {
      this.showSidebar = !this.showSidebar
    },
    hideSidebar: function() {
      this.showSidebar = false
    },
    // Select a group
    selectGroup: function(group, demoMode = false) {
      event.preventDefault()
      this.setGroup(group)
      this.updateRouteGroupId(group, demoMode)
      this.groupSelect = false
    },
    // Reset group
    resetGroup: function() {
      this.setGroup(this.user.group)
      this.updateRouteGroupId(this.user.group, false)
    },
    demoMode: function() {
      this.demoModeEnabled = this.$route.query.demo === 'true'
    },
    CheckIfUserHasDemoGroup: function() {
      if (this.user.actAsGroup === undefined) {
        event.preventDefault()
        this.$root.$bvToast.toast(this.$t('errors.noDemoGroup'), {
          variant: 'danger',
          noCloseButton: true,
          autoHideDelay: 3000
        })
      }
    },
    toggleDemoMode: function() {
      if (this.demoModeEnabled) {
        this.selectGroup(this.user.actAsGroup, true)
      } else {
        this.resetGroup()
      }
    },
    // After group has changed, try to update route groupId
    updateRouteGroupId: function(group, demoMode) {
      const route = this.$router.currentRoute
      let location

      // Specific cases
      const groupRouteNames = ['posts', 'widgets', 'multiproductWidgets', 'stores', 'facebookApps', 'multiproductLandingPages', 'popinImplementations', 'customServices', 'customisations', 'michelinWidgetCustomisations', 'productFilters', 'oneClicks', 'products', 'groupApiKeys', 'abTests', 'groups', 'users']
      if (groupRouteNames.map(name => `group${upperFirst(name)}`).includes(route.name) && group.id === this.user.group.id) {
        location = { name: lowerFirst(route.name.replace('group', '')) }
      } else if (groupRouteNames.includes(route.name)) {
        location = { name: `group${upperFirst(route.name)}`, params: { groupId: group.id } }
      } else {
        const name = route.name ? route.name : ''

        location = {
          name,
          params: {
            ...route.params,
            groupId: group.id
          },
          query: route.query
        }
      }

      if (demoMode) {
        location.query = { ...location.query, demo: 'true' }

        if (route.name === 'selfEditUser') {
          this.$router.push(location)
        }
      } else if (location.query && location.query.demo) {
        if (route.name === 'selfEditUser') {
          location.query = { demo: 'false' }
          this.$router.push(location)
        }

        delete location.query.demo
      }

      // Redirect if new location resolves to a different path
      if (this.$router.resolve(location).route.path !== route.path) {
        this.$router.push(location)
      }
    },
    // Set custom breadcrumb
    setCustomBreadcrumb: function(breadcrumb) {
      this.customBreadcrumb = breadcrumb
    }
  },
  watch: {
    // Hide sidebar when route changes
    $route: function(to, from) {
      this.hideSidebar()
    },
    // Lock body scroll when sidebar is shown
    showSidebar: function(showSidebar) {
      if (showSidebar) {
        document.body.classList.add('overflow-hidden')
      } else {
        document.body.classList.remove('overflow-hidden')
      }
    }
  },
  created: function() {
    this.demoMode()
  }
}
</script>

<style lang="scss">
$navbar-height: 57px;
$navbar-height-mobile: 84px;

.navbar {
  padding: 0;
  height: $navbar-height;

  .navbar-left {
    min-width: 250px;
    height: 57px;
    padding: 0 1rem;
    border-right: 1px solid #dee2e6;

    .navbar-logo {
      max-width: 100px;
      height: 30px;
      color: #000;
      margin-top: 2px;
    }

    .navbar-app-name {
      margin-left: 5px;
      font-size: 1rem;
      position: relative;

      .navbar-app-environment {
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }

  .navbar-right {
    padding: .5rem 1rem;

    .nav-link {
      color: #212529;
      padding: .5rem;
    }

    .dropdown-menu {
      top: calc(100% + 15px);
    }
  }
}

@media (max-width: 767px) {
  .navbar {
    height: $navbar-height-mobile;

    .navbar-left {
      width: 100%;
      border-right: 0;

      .navbar-logo {
        max-width: 100px;
        height: 22px;
      }
    }

    .navbar-right {
      padding: .25rem 1rem;

      font-size: 14px;
    }
  }
}

.admin-dropdown-icon {
  font-size: 24px;
  line-height: 16px;
}

.admin-dropdown-menu {
  min-width: 250px;
}

.user-img {
  margin-top: -5px;
  margin-bottom: -5px;
  max-height: 29px;
  border-radius: 50%;
}

// Sidebar
.sidebar-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.3);
}

.sidebar {
  position: fixed;
  top: $navbar-height;
  bottom: 0;
  left: 0;
  width: 250px;
  z-index: 1000;
  background-color: #fff;
}

@media (max-width: 767px) {
  .sidebar {
    top: $navbar-height-mobile;

    // Mobile sidebar toggle animation
    transition: transform .25s;

    &.sidebar-mobile-hidden {
      transform: translate3d(-250px, 0, 0);
    }
  }
}

.breadcrumb {
  background-color: #f8f9fa;
  margin-bottom: 0;
  font-size: 1.25rem;
}

.main {
  margin-left: 250px;
  padding-top: $navbar-height;
  width: 100%;
  display: flex;
}

@media (max-width: 767px) {
  .main {
    margin-left: 0;
    padding-top: $navbar-height-mobile;
  }
}

// Content
.content {
  padding: 20px;
  width: 100%;
}
</style>
