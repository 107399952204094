import Vue from 'vue'
import Router from 'vue-router'
import multiguard from 'vue-router-multiguard'

import LoginForm from './views/auth/LoginForm.vue'
import NewPasswordForm from './views/auth/NewPasswordForm.vue'
import EditPasswordForm from './views/auth/EditPasswordForm.vue'
import Layout from './views/layouts/Layout.vue'
import PostsIndex from './views/posts/index.vue'
import PostsForm from './views/posts/form.vue'
import StatsWidgetsTunnel from './views/stats/widgets/tunnel.vue'
import StatsWidgetsRetailers from './views/stats/widgets/retailers.vue'
import StatsWidgetsParentSites from './views/stats/widgets/parent_sites.vue'
import StatsWidgetsDevices from './views/stats/widgets/devices.vue'
import StatsWidgetsPivotTable from './views/stats/widgets/pivot_table.vue'
import StatsWidgetsAbTests from './views/stats/widgets/ab_tests.vue'
import StatsDistribution from './views/stats/distribution.vue'
import StatsMultiproductWidgets from './views/stats/multiproduct_widgets.vue'
import StatsLandings from './views/stats/landings.vue'
import StatsPopins from './views/stats/popins.vue'
import StatsCustomServicesTunnel from './views/stats/custom_services/tunnel.vue'
import StatsCustomServicesPivotTable from './views/stats/custom_services/pivot_table.vue'
import StatsCustomServicesValidations from './views/stats/custom_services/validations.vue'
import StatsReports from './views/stats/reports.vue'
import StatsExports from './views/stats/exports.vue'
import WidgetsIndex from './views/widgets/index.vue'
import WidgetsShow from './views/widgets/show.vue'
import WidgetsForm from './views/widgets/form.vue'
import WidgetBulkUpdatesIndex from './views/widget_bulk_updates/index.vue'
import WidgetBulkUpdatesShow from './views/widget_bulk_updates/show.vue'
import WidgetBulkUpdatesForm from './views/widget_bulk_updates/form.vue'
import MultiproductWidgetsIndex from './views/multiproduct_widgets/index.vue'
import MultiproductWidgetsShow from './views/multiproduct_widgets/show.vue'
import MultiproductWidgetsForm from './views/multiproduct_widgets/form.vue'
import StoresIndex from './views/stores/index.vue'
import StoresShow from './views/stores/show.vue'
import StoresForm from './views/stores/form.vue'
import FacebookAppsIndex from './views/facebook_apps/index.vue'
import FacebookAppsShow from './views/facebook_apps/show.vue'
import FacebookAppsForm from './views/facebook_apps/form.vue'
import MultiproductLandingPagesIndex from './views/multiproduct_landing_pages/index.vue'
import MultiproductLandingPagesShow from './views/multiproduct_landing_pages/show.vue'
import MultiproductLandingPagesForm from './views/multiproduct_landing_pages/form.vue'
import LandingPagesIndex from './views/landing_pages/index.vue'
import LandingPagesShow from './views/landing_pages/show.vue'
import LandingPagesForm from './views/landing_pages/form.vue'
import PopinImplementationsIndex from './views/popin_implementations/index.vue'
import PopinImplementationsShow from './views/popin_implementations/show.vue'
import PopinImplementationsForm from './views/popin_implementations/form.vue'
import CustomServicesIndex from './views/custom_services/index.vue'
import CustomServicesShow from './views/custom_services/show.vue'
import CustomServicesForm from './views/custom_services/form.vue'
import CustomisationsIndex from './views/customisations/index.vue'
import CustomisationsShow from './views/customisations/show.vue'
import CustomisationsForm from './views/customisations/form.vue'
import MultiproductWidgetCustomisationsIndex from './views/multiproduct_widget_customisations/index.vue'
import MultiproductWidgetCustomisationsShow from './views/multiproduct_widget_customisations/show.vue'
import MultiproductWidgetCustomisationsForm from './views/multiproduct_widget_customisations/form.vue'
import MichelinWidgetCustomisationsIndex from './views/michelin_widget_customisations/index.vue'
import MichelinWidgetCustomisationsForm from './views/michelin_widget_customisations/form.vue'
import ProductFiltersIndex from './views/product_filters/index.vue'
import ProductFiltersShow from './views/product_filters/show.vue'
import ProductFiltersForm from './views/product_filters/form.vue'
import OneClicksIndex from './views/one_clicks/index.vue'
import OneClicksShow from './views/one_clicks/show.vue'
import OneClicksForm from './views/one_clicks/form.vue'
import GroupApiKeysIndex from './views/group_api_keys/index.vue'
import GroupApiKeysShow from './views/group_api_keys/show.vue'
import GroupApiKeysForm from './views/group_api_keys/form.vue'
import AbTestsIndex from './views/ab_tests/index.vue'
import AbTestsForm from './views/ab_tests/form.vue'
import MichelinOffersIndex from './views/michelin_offers/index.vue'
import MichelinOffersForm from './views/michelin_offers/form.vue'
import MichelinImportMsrpForm from './views/michelin_import_msrp/form.vue'
import ProductsIndex from './views/products/index.vue'
import ProductsShow from './views/products/show.vue'
import ProductsForm from './views/products/form.vue'
import ImportTrackersIndex from './views/import_trackers/index.vue'
import ImportTrackersShow from './views/import_trackers/show.vue'
import ImportTrackersForm from './views/import_trackers/form.vue'
import ImportTrackersImages from './views/import_trackers/images.vue'
import PimDataSourcesIndex from './views/pim_data_sources/index.vue'
import PimDataSourcesForm from './views/pim_data_sources/form.vue'
import PimDataSourcesShow from './views/pim_data_sources/show.vue'
import PimJobLogsShow from './views/pim_data_sources/jobs.vue'
import PimDataSourcesUnknownGroups from './views/pim_data_sources/unknown_groups.vue'
import CustomPagesShow from './views/custom_pages/show.vue'
import GroupsIndex from './views/groups/index.vue'
import GroupsShow from './views/groups/show.vue'
import GroupsForm from './views/groups/form.vue'
import UsersIndex from './views/users/index.vue'
import UsersShow from './views/users/show.vue'
import UsersForm from './views/users/form.vue'

import store from './store'
import i18n from './i18n'
import { ability } from './store/ability'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    { path: '/login', name: 'login', component: LoginForm, meta: { requiresAuth: false } },
    { path: '/auth/password/new', name: 'newPassword', component: NewPasswordForm, meta: { requiresAuth: false } },
    { path: '/auth/password/edit', name: 'editPassword', component: EditPasswordForm, meta: { requiresAuth: false } },
    {
      path: '',
      component: Layout,
      children: [
        { path: '/', redirect: { name: 'posts' } },
        { path: '/posts', name: 'posts', component: PostsIndex, meta: { breadcrumb: ['breadcrumb.posts.index'], resource: 'Post' } },
        { path: '/posts/group/:groupId', name: 'groupPosts', component: PostsIndex, meta: { breadcrumb: ['breadcrumb.posts.index'], resource: 'Post' } },
        { path: '/posts/new', name: 'newPost', component: PostsForm, props: { action: 'new' }, meta: { breadcrumb: ['breadcrumb.posts.new'], resource: 'Post' } },
        { path: '/posts/:id/edit', name: 'editPost', component: PostsForm, props: { action: 'edit' }, meta: { breadcrumb: ['breadcrumb.posts.edit'], resource: 'Post' } },
        { path: '/stats/tunnel/:groupId', name: 'statsTunnel', component: StatsWidgetsTunnel, meta: { breadcrumb: ['breadcrumb.stats.group', 'breadcrumb.stats.widgets.group', 'breadcrumb.stats.widgets.tunnel'], resource: 'stats' } },
        { path: '/stats/retailers/:groupId', name: 'statsRetailers', component: StatsWidgetsRetailers, meta: { breadcrumb: ['breadcrumb.stats.group', 'breadcrumb.stats.widgets.group', 'breadcrumb.stats.widgets.retailers'], resource: 'stats' } },
        { path: '/stats/parent_sites/:groupId', name: 'statsParentSites', component: StatsWidgetsParentSites, meta: { breadcrumb: ['breadcrumb.stats.group', 'breadcrumb.stats.widgets.group', 'breadcrumb.stats.widgets.parentSites'], resource: 'stats' } },
        { path: '/stats/devices/:groupId', name: 'statsDevices', component: StatsWidgetsDevices, meta: { breadcrumb: ['breadcrumb.stats.group', 'breadcrumb.stats.widgets.group', 'breadcrumb.stats.widgets.devices'], resource: 'stats' } },
        { path: '/stats/pivot_table/:groupId', name: 'statsPivotTable', component: StatsWidgetsPivotTable, meta: { breadcrumb: ['breadcrumb.stats.group', 'breadcrumb.stats.widgets.group', 'breadcrumb.stats.widgets.pivotTable'], resource: 'stats' } },
        { path: '/stats/ab_tests/:groupId', name: 'statsAbTests', component: StatsWidgetsAbTests, meta: { breadcrumb: ['breadcrumb.stats.group', 'breadcrumb.stats.widgets.group', 'breadcrumb.stats.abTests'], resource: 'stats' } },
        { path: '/stats/distribution/:groupId', name: 'statsDistribution', component: StatsDistribution, meta: { breadcrumb: ['breadcrumb.stats.group', 'breadcrumb.stats.distribution'], resource: 'stats' } },
        { path: '/stats/multiproduct_widgets/:groupId', name: 'statsMultiproductWidgets', component: StatsMultiproductWidgets, meta: { breadcrumb: ['breadcrumb.stats.group', 'breadcrumb.stats.multiproductWidgets'], resource: 'stats' } },
        { path: '/stats/landings/:groupId', name: 'statsLandings', component: StatsLandings, meta: { breadcrumb: ['breadcrumb.stats.group', 'breadcrumb.stats.landings'], resource: 'stats' } },
        { path: '/stats/popins/:groupId', name: 'statsPopins', component: StatsPopins, meta: { breadcrumb: ['breadcrumb.stats.group', 'breadcrumb.stats.popins'], resource: 'stats' } },
        { path: '/stats/custom_services/tunnel/:groupId', name: 'statsCustomServicesTunnel', component: StatsCustomServicesTunnel, meta: { breadcrumb: ['breadcrumb.stats.group', 'breadcrumb.stats.customServices.group', 'breadcrumb.stats.customServices.tunnel'], resource: 'stats' } },
        { path: '/stats/custom_services/pivot_table/:groupId', name: 'statsCustomServicesPivotTable', component: StatsCustomServicesPivotTable, meta: { breadcrumb: ['breadcrumb.stats.group', 'breadcrumb.stats.customServices.group', 'breadcrumb.stats.customServices.pivotTable'], resource: 'stats' } },
        { path: '/stats/custom_services/validations/:groupId', name: 'statsCustomServicesValidations', component: StatsCustomServicesValidations, meta: { breadcrumb: ['breadcrumb.stats.group', 'breadcrumb.stats.customServices.group', 'breadcrumb.stats.customServices.validations'], resource: 'stats' } },
        { path: '/stats/reports/:groupId', name: 'statsReports', component: StatsReports, meta: { breadcrumb: ['breadcrumb.stats.group', 'breadcrumb.stats.reports'], resource: 'stats' } },
        { path: '/stats/exports', name: 'statsExports', component: StatsExports, meta: { breadcrumb: ['breadcrumb.stats.group', 'breadcrumb.stats.exports'], resource: 'stats' } },
        { path: '/widgets', name: 'widgets', component: WidgetsIndex, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.widgets.group', 'breadcrumb.services.widgets.index'], resource: 'Widget' } },
        { path: '/widgets/group/:groupId', name: 'groupWidgets', component: WidgetsIndex, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.widgets.group', 'breadcrumb.services.widgets.index'], resource: 'Widget' } },
        { path: '/widgets/new', name: 'newWidget', component: WidgetsForm, props: { action: 'new' }, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.widgets.group', 'breadcrumb.services.widgets.new'], resource: 'Widget' } },
        { path: '/widget_bulk_updates', name: 'widgetBulkUpdates', component: WidgetBulkUpdatesIndex, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.widgets.group', 'breadcrumb.services.widgetBulkUpdates.index'], resource: 'WidgetBulkUpdate' } },
        { path: '/widget_bulk_updates/new', name: 'newWidgetBulkUpdate', component: WidgetBulkUpdatesForm, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.widgets.group', 'breadcrumb.services.widgetBulkUpdates.new'], resource: 'WidgetBulkUpdate' } },
        { path: '/widget_bulk_updates/:id', name: 'widgetBulkUpdate', component: WidgetBulkUpdatesShow, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.widgets.group', 'breadcrumb.services.widgetBulkUpdates.show'], resource: 'WidgetBulkUpdate' } },
        { path: '/widgets/:id', name: 'widget', component: WidgetsShow, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.widgets.group', 'breadcrumb.services.widgets.show'], resource: 'Widget' } },
        { path: '/widgets/:id/edit', name: 'editWidget', component: WidgetsForm, props: { action: 'edit' }, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.widgets.group', 'breadcrumb.services.widgets.edit'], resource: 'Widget' } },
        { path: '/multiproduct_widgets', name: 'multiproductWidgets', component: MultiproductWidgetsIndex, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.multiproductWidgets.group', 'breadcrumb.services.multiproductWidgets.index'], resource: 'MultiproductWidget' } },
        { path: '/multiproduct_widgets/group/:groupId', name: 'groupMultiproductWidgets', component: MultiproductWidgetsIndex, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.multiproductWidgets.group', 'breadcrumb.services.multiproductWidgets.index'], resource: 'MultiproductWidget' } },
        { path: '/multiproduct_widgets/new', name: 'newMultiproductWidget', component: MultiproductWidgetsForm, props: { action: 'new' }, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.multiproductWidgets.group', 'breadcrumb.services.multiproductWidgets.new'], resource: 'MultiproductWidget' } },
        { path: '/multiproduct_widgets/:id', name: 'multiproductWidget', component: MultiproductWidgetsShow, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.multiproductWidgets.group', 'breadcrumb.services.multiproductWidgets.show'], resource: 'MultiproductWidget' } },
        { path: '/multiproduct_widgets/:id/edit', name: 'editMultiproductWidget', component: MultiproductWidgetsForm, props: { action: 'edit' }, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.multiproductWidgets.group', 'breadcrumb.services.multiproductWidgets.edit'], resource: 'MultiproductWidget' } },
        { path: '/stores', name: 'stores', component: StoresIndex, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.stores.group', 'breadcrumb.services.stores.index'], resource: 'Shop' } },
        { path: '/stores/group/:groupId', name: 'groupStores', component: StoresIndex, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.stores.group', 'breadcrumb.services.stores.index'], resource: 'Shop' } },
        { path: '/stores/new', name: 'newStore', component: StoresForm, props: { action: 'new' }, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.stores.group', 'breadcrumb.services.stores.new'], resource: 'Store' } },
        { path: '/stores/:id', name: 'store', component: StoresShow, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.stores.group', 'breadcrumb.services.stores.show'], resource: 'Store' } },
        { path: '/stores/:id/edit', name: 'editStore', component: StoresForm, props: { action: 'edit' }, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.stores.group', 'breadcrumb.services.stores.edit'], resource: 'Store' } },
        { path: '/facebook_apps', name: 'facebookApps', component: FacebookAppsIndex, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.facebookApps.group', 'breadcrumb.services.facebookApps.index'], resource: 'FacebookApp' } },
        { path: '/facebook_apps/group/:groupId', name: 'groupFacebookApps', component: FacebookAppsIndex, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.facebookApps.group', 'breadcrumb.services.facebookApps.index'], resource: 'FacebookApp' } },
        { path: '/facebook_apps/new', name: 'newFacebookApp', component: FacebookAppsForm, props: { action: 'new' }, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.facebookApps.group', 'breadcrumb.services.facebookApps.new'], resource: 'FacebookApp' } },
        { path: '/facebook_apps/:id', name: 'facebookApp', component: FacebookAppsShow, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.facebookApps.group', 'breadcrumb.services.facebookApps.show'], resource: 'FacebookApp' } },
        { path: '/facebook_apps/:id/edit', name: 'editFacebookApp', component: FacebookAppsForm, props: { action: 'edit' }, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.facebookApps.group', 'breadcrumb.services.facebookApps.edit'], resource: 'FacebookApp' } },
        { path: '/landing_pages', name: 'landingPages', component: LandingPagesIndex, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.landingPages.group', 'breadcrumb.services.landingPages.index'], resource: 'LandingPage' } },
        { path: '/landing_pages/new', name: 'newLandingPage', component: LandingPagesForm, props: { action: 'new' }, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.landingPages.group', 'breadcrumb.services.landingPages.new'], resource: 'LandingPage' } },
        { path: '/landing_pages/:id', name: 'landingPage', component: LandingPagesShow, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.landingPages.group', 'breadcrumb.services.landingPages.show'], resource: 'LandingPage' } },
        { path: '/landing_pages/:id/edit', name: 'editLandingPage', component: LandingPagesForm, props: { action: 'edit' }, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.multiproductLandingPages.group', 'breadcrumb.services.landingPages.edit'], resource: 'LandingPage' } },
        { path: '/multiproduct_landing_pages', name: 'multiproductLandingPages', component: MultiproductLandingPagesIndex, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.multiproductLandingPages.group', 'breadcrumb.services.multiproductLandingPages.index'], resource: 'MultiproductLandingPage' } },
        { path: '/multiproduct_landing_pages/group/:groupId', name: 'groupMultiproductLandingPages', component: MultiproductLandingPagesIndex, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.multiproductLandingPages.group', 'breadcrumb.services.multiproductLandingPages.index'], resource: 'MultiproductLandingPage' } },
        { path: '/multiproduct_landing_pages/new', name: 'newMultiproductLandingPage', component: MultiproductLandingPagesForm, props: { action: 'new' }, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.multiproductLandingPages.group', 'breadcrumb.services.multiproductLandingPages.new'], resource: 'MultiproductLandingPage' } },
        { path: '/multiproduct_landing_pages/:id', name: 'multiproductLandingPage', component: MultiproductLandingPagesShow, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.multiproductLandingPages.group', 'breadcrumb.services.multiproductLandingPages.show'], resource: 'MultiproductLandingPage' } },
        { path: '/multiproduct_landing_pages/:id/edit', name: 'editMultiproductLandingPage', component: MultiproductLandingPagesForm, props: { action: 'edit' }, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.multiproductLandingPages.group', 'breadcrumb.services.multiproductLandingPages.edit'], resource: 'MultiproductLandingPage' } },
        { path: '/popin_implementations', name: 'popinImplementations', component: PopinImplementationsIndex, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.popinImplementations.group', 'breadcrumb.services.popinImplementations.index'], resource: 'PopinImplementation' } },
        { path: '/popin_implementations/group/:groupId', name: 'groupPopinImplementations', component: PopinImplementationsIndex, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.popinImplementations.group', 'breadcrumb.services.popinImplementations.index'], resource: 'PopinImplementation' } },
        { path: '/popin_implementations/new', name: 'newPopinImplementation', component: PopinImplementationsForm, props: { action: 'new' }, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.popinImplementations.group', 'breadcrumb.services.popinImplementations.new'], resource: 'PopinImplementation' } },
        { path: '/popin_implementations/:id', name: 'popinImplementation', component: PopinImplementationsShow, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.popinImplementations.group', 'breadcrumb.services.popinImplementations.show'], resource: 'PopinImplementation' } },
        { path: '/popin_implementations/:id/edit', name: 'editPopinImplementation', component: PopinImplementationsForm, props: { action: 'edit' }, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.popinImplementations.group', 'breadcrumb.services.popinImplementations.edit'], resource: 'PopinImplementation' } },
        { path: '/custom_services', name: 'customServices', component: CustomServicesIndex, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.customServices.group', 'breadcrumb.services.customServices.index'], resource: 'CustomService' } },
        { path: '/custom_services/group/:groupId', name: 'groupCustomServices', component: CustomServicesIndex, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.customServices.group', 'breadcrumb.services.customServices.index'], resource: 'CustomService' } },
        { path: '/custom_services/new', name: 'newCustomService', component: CustomServicesForm, props: { action: 'new' }, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.customServices.group', 'breadcrumb.services.customServices.new'], resource: 'CustomService' } },
        { path: '/custom_services/:id', name: 'customService', component: CustomServicesShow, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.customServices.group', 'breadcrumb.services.customServices.show'], resource: 'CustomService' } },
        { path: '/custom_services/:id/edit', name: 'editCustomService', component: CustomServicesForm, props: { action: 'edit' }, meta: { breadcrumb: ['breadcrumb.services.group', 'breadcrumb.services.customServices.group', 'breadcrumb.services.customServices.edit'], resource: 'CustomService' } },
        { path: '/customisations', name: 'customisations', component: CustomisationsIndex, meta: { breadcrumb: ['breadcrumb.customisations.group', 'breadcrumb.customisations.widgets.group', 'breadcrumb.customisations.widgets.index'], resource: 'Customisation' } },
        { path: '/customisations/group/:groupId', name: 'groupCustomisations', component: CustomisationsIndex, meta: { breadcrumb: ['breadcrumb.customisations.group', 'breadcrumb.customisations.widgets.group', 'breadcrumb.customisations.widgets.index'], resource: 'Customisation' } },
        { path: '/customisations/new', name: 'newCustomisation', component: CustomisationsForm, props: { action: 'new' }, meta: { breadcrumb: ['breadcrumb.customisations.group', 'breadcrumb.customisations.widgets.group', 'breadcrumb.customisations.widgets.new'], resource: 'Customisation' } },
        { path: '/customisations/:id', name: 'customisation', component: CustomisationsShow, meta: { breadcrumb: ['breadcrumb.customisations.group', 'breadcrumb.customisations.widgets.group', 'breadcrumb.customisations.widgets.show'], resource: 'Customisation' } },
        { path: '/customisations/:id/edit', name: 'editCustomisation', component: CustomisationsForm, props: { action: 'edit' }, meta: { breadcrumb: ['breadcrumb.customisations.group', 'breadcrumb.customisations.widgets.group', 'breadcrumb.customisations.widgets.edit'], resource: 'Customisation' } },
        { path: '/multiproduct_widget_customisations', name: 'multiproductWidgetCustomisations', component: MultiproductWidgetCustomisationsIndex, meta: { breadcrumb: ['breadcrumb.customisations.group', 'breadcrumb.customisations.multiproductWidgetCustomisations.group', 'breadcrumb.customisations.multiproductWidgetCustomisations.index'], resource: 'MultiproductWidgetCustomisation' } },
        { path: '/multiproduct_widget_customisations/group/:groupId', name: 'groupMultiproductWidgetCustomisations', component: MultiproductWidgetCustomisationsIndex, meta: { breadcrumb: ['breadcrumb.customisations.group', 'breadcrumb.customisations.multiproductWidgetCustomisations.group', 'breadcrumb.customisations.multiproductWidgetCustomisations.index'], resource: 'MultiproductWidgetCustomisation' } },
        { path: '/multiproduct_widget_customisations/new', name: 'newMultiproductWidgetCustomisation', component: MultiproductWidgetCustomisationsForm, props: { action: 'new' }, meta: { breadcrumb: ['breadcrumb.customisations.group', 'breadcrumb.customisations.multiproductWidgetCustomisations.group', 'breadcrumb.customisations.multiproductWidgetCustomisations.new'], resource: 'MultiproductWidgetCustomisation' } },
        { path: '/multiproduct_widget_customisations/:id', name: 'multiproductWidgetCustomisation', component: MultiproductWidgetCustomisationsShow, meta: { breadcrumb: ['breadcrumb.customisations.group', 'breadcrumb.customisations.multiproductWidgetCustomisations.group', 'breadcrumb.customisations.multiproductWidgetCustomisations.show'], resource: 'MultiproductWidgetCustomisation' } },
        { path: '/multiproduct_widget_customisations/:id/edit', name: 'editMultiproductWidgetCustomisation', component: MultiproductWidgetCustomisationsForm, props: { action: 'edit' }, meta: { breadcrumb: ['breadcrumb.customisations.group', 'breadcrumb.customisations.multiproductWidgetCustomisations.group', 'breadcrumb.customisations.multiproductWidgetCustomisations.edit'], resource: 'MultiproductWidgetCustomisation' } },
        { path: '/michelin_widget_customisations', name: 'michelinWidgetCustomisations', component: MichelinWidgetCustomisationsIndex, meta: { breadcrumb: ['breadcrumb.customisations.group', 'breadcrumb.customisations.michelinWidgetCustomisations.group', 'breadcrumb.customisations.michelinWidgetCustomisations.index'], resource: 'MichelinWidgetCustomisation' } },
        { path: '/michelin_widget_customisations/group/:groupId', name: 'groupMichelinWidgetCustomisations', component: MichelinWidgetCustomisationsIndex, meta: { breadcrumb: ['breadcrumb.customisations.group', 'breadcrumb.customisations.michelinWidgetCustomisations.group', 'breadcrumb.customisations.michelinWidgetCustomisations.index'], resource: 'MichelinWidgetCustomisation' } },
        { path: '/michelin_widget_customisations/new', name: 'newMichelinWidgetCustomisation', component: MichelinWidgetCustomisationsForm, props: { action: 'new' }, meta: { breadcrumb: ['breadcrumb.customisations.group', 'breadcrumb.customisations.michelinWidgetCustomisations.group', 'breadcrumb.customisations.michelinWidgetCustomisations.new'], resource: 'MichelinWidgetCustomisation' } },
        { path: '/michelin_widget_customisations/:id/edit', name: 'editMichelinWidgetCustomisation', component: MichelinWidgetCustomisationsForm, props: { action: 'edit' }, meta: { breadcrumb: ['breadcrumb.customisations.group', 'breadcrumb.customisations.michelinWidgetCustomisations.group', 'breadcrumb.customisations.michelinWidgetCustomisations.edit'], resource: 'MichelinWidgetCustomisation' } },
        { path: '/product_filters', name: 'productFilters', component: ProductFiltersIndex, meta: { breadcrumb: ['breadcrumb.customisations.group', 'breadcrumb.customisations.productFilters.group', 'breadcrumb.customisations.productFilters.index'], resource: 'ProductFilter' } },
        { path: '/product_filters/group/:groupId', name: 'groupProductFilters', component: ProductFiltersIndex, meta: { breadcrumb: ['breadcrumb.customisations.group', 'breadcrumb.customisations.productFilters.group', 'breadcrumb.customisations.productFilters.index'], resource: 'ProductFilter' } },
        { path: '/product_filters/new', name: 'newProductFilter', component: ProductFiltersForm, props: { action: 'new' }, meta: { breadcrumb: ['breadcrumb.customisations.group', 'breadcrumb.customisations.productFilters.group', 'breadcrumb.customisations.productFilters.new'], resource: 'ProductFilter' } },
        { path: '/product_filters/:id', name: 'productFilter', component: ProductFiltersShow, meta: { breadcrumb: ['breadcrumb.customisations.group', 'breadcrumb.customisations.productFilters.group', 'breadcrumb.customisations.productFilters.show'], resource: 'ProductFilter' } },
        { path: '/product_filters/:id/edit', name: 'editProductFilter', component: ProductFiltersForm, props: { action: 'edit' }, meta: { breadcrumb: ['breadcrumb.customisations.group', 'breadcrumb.customisations.productFilters.group', 'breadcrumb.customisations.productFilters.edit'], resource: 'ProductFilter' } },
        { path: '/one_clicks', name: 'oneClicks', component: OneClicksIndex, meta: { breadcrumb: ['breadcrumb.customisations.group', 'breadcrumb.customisations.oneClicks.group', 'breadcrumb.customisations.oneClicks.index'], resource: 'OneClick' } },
        { path: '/one_clicks/group/:groupId', name: 'groupOneClicks', component: OneClicksIndex, meta: { breadcrumb: ['breadcrumb.customisations.group', 'breadcrumb.customisations.oneClicks.group', 'breadcrumb.customisations.oneClicks.index'], resource: 'OneClick' } },
        { path: '/one_clicks/new', name: 'newOneClick', component: OneClicksForm, props: { action: 'new' }, meta: { breadcrumb: ['breadcrumb.customisations.group', 'breadcrumb.customisations.oneClicks.group', 'breadcrumb.customisations.oneClicks.new'], resource: 'MichelinWidgetCustomisation' } },
        { path: '/one_clicks/:id', name: 'oneClick', component: OneClicksShow, meta: { breadcrumb: ['breadcrumb.customisations.group', 'breadcrumb.customisations.oneClicks.group', 'breadcrumb.customisations.oneClicks.show'], resource: 'MichelinWidgetCustomisation' } },
        { path: '/one_clicks/:id/edit', name: 'editOneClick', component: OneClicksForm, props: { action: 'edit' }, meta: { breadcrumb: ['breadcrumb.customisations.group', 'breadcrumb.customisations.oneClicks.group', 'breadcrumb.customisations.oneClicks.edit'], resource: 'MichelinWidgetCustomisation' } },
        { path: '/products', name: 'products', component: ProductsIndex, meta: { breadcrumb: ['breadcrumb.products.group', 'breadcrumb.products.index'], resource: 'Product', action: 'read' } },
        { path: '/products/group/:groupId', name: 'groupProducts', component: ProductsIndex, meta: { breadcrumb: ['breadcrumb.products.group', 'breadcrumb.products.index'], resource: 'Product', action: 'read' } },
        { path: '/products/new', name: 'newProduct', component: ProductsForm, props: { action: 'new' }, meta: { breadcrumb: ['breadcrumb.products.group', 'breadcrumb.products.new'], resource: 'Product' } },
        { path: '/products/:id', name: 'product', component: ProductsShow, meta: { breadcrumb: ['breadcrumb.products.group', 'breadcrumb.products.show'], resource: 'Product' } },
        { path: '/products/:id/edit', name: 'editProduct', component: ProductsForm, props: { action: 'edit' }, meta: { breadcrumb: ['breadcrumb.products.group', 'breadcrumb.products.edit'], resource: 'Product' } },
        { path: '/import_trackers', name: 'importTrackers', component: ImportTrackersIndex, meta: { breadcrumb: ['breadcrumb.products.group', 'breadcrumb.products.importTrackers.group', 'breadcrumb.products.importTrackers.index'], resource: 'ImportTracker' } },
        { path: '/import_trackers/new', name: 'newImportTracker', component: ImportTrackersForm, props: { action: 'new' }, meta: { breadcrumb: ['breadcrumb.products.group', 'breadcrumb.products.importTrackers.group', 'breadcrumb.products.importTrackers.new'], resource: 'ImportTracker' } },
        { path: '/import_trackers/:id', name: 'importTracker', component: ImportTrackersShow, meta: { breadcrumb: ['breadcrumb.products.group', 'breadcrumb.products.importTrackers.group', 'breadcrumb.products.importTrackers.show'], resource: 'ImportTracker' } },
        { path: '/import_trackers/:id/images', name: 'importTrackerImages', component: ImportTrackersImages, meta: { breadcrumb: ['breadcrumb.products.group', 'breadcrumb.products.importTrackers.group', 'breadcrumb.products.importTrackers.images'], resource: 'ImportTracker' } },
        { path: '/pim_data_sources', name: 'pimDataSources', component: PimDataSourcesIndex, meta: { breadcrumb: ['breadcrumb.products.group', 'breadcrumb.products.pimDataSources.group', 'breadcrumb.products.pimDataSources.index'], resource: 'Product' } },
        { path: '/pim_data_sources/:id/', name: 'showPimDataSource', component: PimDataSourcesShow, meta: { breadcrumb: ['breadcrumb.products.group', 'breadcrumb.products.pimDataSources.group', 'breadcrumb.products.pimDataSources.show'], resource: 'Product' } },
        { path: '/pim_data_sources/:id/job/:job_id', name: 'showPimJobLogs', component: PimJobLogsShow, meta: { breadcrumb: ['breadcrumb.products.group', 'breadcrumb.products.pimDataSources.group', 'breadcrumb.products.pimDataSources.show', 'breadcrumb.products.pimDataSources.jobLogs'], resource: 'Product' } },
        { path: '/pim_data_sources/:id/edit', name: 'editPimDataSource', component: PimDataSourcesForm, props: { action: 'edit' }, meta: { breadcrumb: ['breadcrumb.products.group', 'breadcrumb.products.pimDataSources.group', 'breadcrumb.products.pimDataSources.edit'], resource: 'Product' } },
        { path: '/pim_data_sources/:id/unknown_groups', name: 'pimDataSourceUnknownGroups', component: PimDataSourcesUnknownGroups, meta: { breadcrumb: ['breadcrumb.products.group', 'breadcrumb.products.pimDataSources.group', 'breadcrumb.products.pimDataSources.show', 'breadcrumb.products.pimDataSources.unknownGroups'], resource: 'Product' } },
        { path: '/ab_tests', name: 'abTests', component: AbTestsIndex, meta: { breadcrumb: ['breadcrumb.abTests.group', 'breadcrumb.abTests.index'], resource: 'AbTest', action: 'read' } },
        { path: '/ab_tests/group/:groupId', name: 'groupAbTests', component: AbTestsIndex, meta: { breadcrumb: ['breadcrumb.abTests.group', 'breadcrumb.abTests.index'], resource: 'AbTest', action: 'read' } },
        { path: '/ab_tests/new', name: 'newAbTest', component: AbTestsForm, props: { action: 'new' }, meta: { breadcrumb: ['breadcrumb.abTests.group', 'breadcrumb.abTests.new'], resource: 'AbTest' } },
        { path: '/ab_tests/:id/edit', name: 'editAbTest', component: AbTestsForm, props: { action: 'edit' }, meta: { breadcrumb: ['breadcrumb.abTests.group', 'breadcrumb.abTests.edit'], resource: 'AbTest' } },
        { path: '/michelin_offers', name: 'michelinOffers', component: MichelinOffersIndex, meta: { breadcrumb: ['breadcrumb.michelin.group', 'breadcrumb.michelin.offers.group', 'breadcrumb.michelin.offers.index'], resource: 'MichelinOffer', action: 'read' } },
        { path: '/michelin_offers/group/:groupId', name: 'groupMichelinOffers', component: MichelinOffersIndex, meta: { breadcrumb: ['breadcrumb.michelin.group', 'breadcrumb.michelin.offers.group', 'breadcrumb.michelin.offers.index'], resource: 'MichelinOffer', action: 'read' } },
        { path: '/michelin_offers/new', name: 'newMichelinOffer', component: MichelinOffersForm, props: { action: 'new' }, meta: { breadcrumb: ['breadcrumb.michelin.group', 'breadcrumb.michelin.offers.group', 'breadcrumb.michelin.offers.new'], resource: 'MichelinOffer' } },
        { path: '/michelin_offers/:id/edit', name: 'editMichelinOffer', component: MichelinOffersForm, props: { action: 'edit' }, meta: { breadcrumb: ['breadcrumb.michelin.group', 'breadcrumb.michelin.offers.group', 'breadcrumb.michelin.offers.edit'], resource: 'MichelinOffer' } },
        { path: '/michelin_import_msrp', name: 'michelinImportMsrp', component: MichelinImportMsrpForm, meta: { breadcrumb: ['breadcrumb.michelin.group', 'breadcrumb.michelin.importMsrp'], resource: 'MichelinImportMsrp' } },
        { path: '/custom_pages/:id', name: 'customPage', component: CustomPagesShow, meta: { resource: 'CustomPage', action: 'read' } },
        { path: '/group_api_keys', name: 'groupApiKeys', component: GroupApiKeysIndex, meta: { breadcrumb: ['breadcrumb.groupApiKeys.group', 'breadcrumb.groupApiKeys.index'], resource: 'GroupAPIKey', action: 'read' } },
        { path: '/group_api_keys/group/:groupId', name: 'groupGroupApiKeys', component: GroupApiKeysIndex, meta: { breadcrumb: ['breadcrumb.groupApiKeys.group', 'breadcrumb.groupApiKeys.index'], resource: 'GroupAPIKey', action: 'read' } },
        { path: '/group_api_keys/new', name: 'newGroupApiKey', component: GroupApiKeysForm, props: { action: 'new' }, meta: { breadcrumb: ['breadcrumb.groupApiKeys.group', 'breadcrumb.groupApiKeys.new'], resource: 'GroupAPIKey' } },
        { path: '/group_api_keys/:id', name: 'groupApiKey', component: GroupApiKeysShow, meta: { breadcrumb: ['breadcrumb.groupApiKeys.group', 'breadcrumb.groupApiKeys.show'], resource: 'GroupAPIKey' } },
        { path: '/group_api_keys/:id/edit', name: 'editGroupApiKey', component: GroupApiKeysForm, props: { action: 'edit' }, meta: { breadcrumb: ['breadcrumb.groupApiKeys.group', 'breadcrumb.groupApiKeys.edit'], resource: 'GroupAPIKey' } },
        { path: '/groups', name: 'groups', component: GroupsIndex, meta: { breadcrumb: ['breadcrumb.groups.group', 'breadcrumb.groups.index'], resource: 'Group' } },
        { path: '/groups/group/:groupId', name: 'groupGroups', component: GroupsIndex, meta: { breadcrumb: ['breadcrumb.groups.group', 'breadcrumb.groups.index'], resource: 'Group' } },
        { path: '/groups/:id', name: 'group', component: GroupsShow, meta: { breadcrumb: ['breadcrumb.groups.group', 'breadcrumb.groups.show'], resource: 'Group' } },
        { path: '/groups/:id/edit', name: 'editGroup', component: GroupsForm, meta: { breadcrumb: ['breadcrumb.groups.group', 'breadcrumb.groups.edit'], resource: 'Group' } },
        { path: '/users', name: 'users', component: UsersIndex, meta: { breadcrumb: ['breadcrumb.users.group', 'breadcrumb.users.index'], resource: 'User' } },
        { path: '/users/group/:groupId', name: 'groupUsers', component: UsersIndex, meta: { breadcrumb: ['breadcrumb.users.group', 'breadcrumb.users.index'], resource: 'User' } },
        { path: '/users/new', name: 'newUser', component: UsersForm, props: { action: 'new' }, meta: { breadcrumb: ['breadcrumb.users.group', 'breadcrumb.users.new'], resource: 'User' } },
        { path: '/users/edit', name: 'selfEditUser', component: UsersForm, props: { action: 'selfEdit' }, meta: { breadcrumb: ['breadcrumb.users.group', 'breadcrumb.users.selfEdit'], resource: 'User', action: 'self_edit' } },
        { path: '/users/:id', name: 'user', component: UsersShow, meta: { breadcrumb: ['breadcrumb.users.group', 'breadcrumb.users.show'], resource: 'User' } },
        { path: '/users/:id/edit', name: 'editUser', component: UsersForm, props: { action: 'edit' }, meta: { breadcrumb: ['breadcrumb.users.group', 'breadcrumb.users.edit'], resource: 'User' } }
      ]
    },
    { path: '*', redirect: '/' }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth === false)) {
    return requireNoAuthentication(to, from, next)
  } else {
    return multiguard([authenticateUser, authorizeUser, setGroup])(to, from, next)
  }
})

// Navigation guards

// Pages not requiring authentication
// Check if user is not logged in, otherwise redirect to homepage
const requireNoAuthentication = async (to, from, next) => {
  if (store.getters['auth/user']) {
    next({ path: '/' })
  } else {
    await store.dispatch('auth/authenticate')
    if (store.getters['auth/user']) {
      next({ path: '/' })
    } else {
      next()
    }
  }
}

// Pages requiring authentication
// Check if user is logged in, otherwise redirect to login page
const authenticateUser = async (to, from, next) => {
  if (!store.getters['auth/user']) {
    await store.dispatch('auth/authenticate')
  }

  if (!store.getters['auth/user']) {
    next({
      name: 'login',
      query: { redirect: to.fullPath }
    })

    new Vue().$bvToast.toast(i18n.t('auth.failure.unauthenticatedNotification'), {
      title: i18n.t('shared.notification'),
      variant: 'danger',
      autoHideDelay: 3000
    })
  } else {
    next()
  }
}

// Check if user can access ressource
const authorizeUser = (to, from, next) => {
  if (to.path !== '/login' && to.path !== '/' && to.meta.resource) {
    const canNavigate = to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))

    if (!canNavigate) {
      new Vue().$bvToast.toast(i18n.t('errors.unauthorized.manage.all'), {
        variant: 'danger',
        noCloseButton: true,
        autoHideDelay: 3000
      })

      // If coming from nowhere, redirect to homepage, otherwise stay on previous page
      if (from.path === '/') {
        next('/')
      } else {
        next(false)
      }
    } else {
      next()
    }
  } else {
    next()
  }
}

// If the route has a groupId param, set group
const setGroup = async (to, from, next) => {
  if (!to.params.groupId) {
    next()
  } else {
    await store.dispatch('auth/loadGroup', to.params.groupId)
    next()
  }
}

export default router
