
<div>
  <transition name="fade" mode="out-in">
    <div v-if="productFilterLoading" class="text-center">
      <md-spinner md-indeterminate></md-spinner>
    </div>
    <div v-else-if="productFilterError" class="alert alert-danger">
      <template v-if="productFilterError.status === 403">
        {{ $t('errors.unauthorized.manage.all') }}
      </template>
      <template v-else>
        {{ $t('errors.internalServerError') }}
      </template>
    </div>
    <div v-else>
      <div class="mb-2 d-flex justify-content-between align-items-end">
        <h5>{{ productFilter.name }}</h5>
        <router-link
          :to="{ name: 'editProductFilter', params: { id: $route.params.id } }"
          class="btn btn-warning">
          {{ $t('shared.actions.edit') }}
        </router-link>
      </div>

      <div class="form-group row">
        <label for="input-name" class="col-sm-2">{{ $t('attributes.productFilter.name') }}</label>
        <div class="col-lg-4 col-sm-6 col-12">
          {{ productFilter.name }}
        </div>
      </div>

      <div class="form-group row">
        <label for="multiselect-groups" class="col-sm-2">{{ $t('attributes.productFilter.group') }}</label>
        <div class="col-lg-4 col-sm-6 col-12">
          <router-link :to="{ name: 'group', params: { id: productFilter.group.id } }">
            <region-flag :code="productFilter.group.region.code" />
            {{ productFilter.group.name }}
          </router-link>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-2">{{ $t('attributes.productFilter.filterValues') }}</label>
        <div class="col-sm-10 col-12">
          <div v-if="productFilter.filterValues.length === 0" class="alert alert-warning">
            {{ $t('shared.warnings.noFilterValue') }}
          </div>
          <table v-else class="table">
            <thead>
              <tr>
                <th class="th-shrink">{{ $t('attributes.filterValue.id') }}</th>
                <th>{{ $t('attributes.filterValue.value') }}</th>
                <th>{{ $t('attributes.filterValue.productsCount') }}</th>
                <th class="th-shrink" />
              </tr>
            </thead>
            <tbody>
              <tr v-for="filterValue in productFilter.filterValues" :key="filterValue.id">
                <td>{{ filterValue.id }}</td>
                <td>{{ filterValue.value }}</td>
                <td>{{ filterValue.products.length }}</td>
                <td class="text-center">
                  <button type="button" class="btn btn-sm btn-primary" @click="showFilterValue(filterValue)">
                    {{ $t('shared.actions.show') }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Filter value modal -->
      <b-modal
        id="filterValueModal"
        ref="filterValueModal"
        size="xl"
        :title="$t('views.productFilters.show.filterValue')"
        ok-only
        no-fade
      >
        <div class="form-group row">
          <label for="filter-value-value" class="col-sm-2">{{ $t('attributes.filterValue.value') }}</label>
          <div class="col-lg-4 col-sm-6 col-12">{{ filterValueModal.value }}</div>
        </div>

        <!-- Products -->
        <div class="form-group row">
          <label class="col-sm-2">{{ $t('attributes.filterValue.products') }}</label>
          <div class="col-sm-10 col-12">
            <div v-if="filterValueModal.products.length === 0" class="alert alert-warning">
              {{ $t('shared.warnings.noProduct') }}
            </div>
            <table v-else class="table table-hover">
              <thead>
                <tr>
                  <th class="th-shrink">{{ $t('attributes.product.id') }}</th>
                  <th class="th-shrink">{{ $t('attributes.product.img') }}</th>
                  <th>{{ $t('attributes.productLanguageData.name') }}</th>
                  <th>{{ $t('attributes.productLanguageData.rawPackaging') }}</th>
                  <th>{{ $t('attributes.product.ean') }}</th>
                  <th>{{ $t('attributes.product.upc') }}</th>
                  <th class="th-shrink"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="product in filterValueModal.products"
                  :key="product.id"
                >
                  <th>{{ product.id }}</th>
                  <td>
                    <img
                      v-if="product.img"
                      :src="product.img.url"
                      class="filter-value-product-img"
                    />
                  </td>
                  <td>{{ product.productLanguageDatas[0].name }}</td>
                  <td>{{ product.productLanguageDatas[0].rawPackaging }}</td>
                  <td>{{ product.ean }}</td>
                  <td>{{ product.upc }}</td>
                  <td class="text-nowrap">
                    <router-link :to="{ name: 'product', params: { id: product.id } }">
                      <button type="button" class="btn btn-sm btn-primary">
                        {{ $t('shared.actions.show') }}
                      </button>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-modal>
    </div>
  </transition>
</div>
