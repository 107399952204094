<template>
  <div>
    <!-- Filters -->
    <div class="mb-4">
      <form @submit.prevent="updateFilters">
        <div class="custom-grid mb-3">
          <div>
            <label for="filter-name">{{ $t('attributes.productFilter.name') }}</label>
            <input
              v-model="filters.name"
              type="text"
              class="form-control"
              id="filter-name"
              :placeholder="$t('attributes.productFilter.name')">
          </div>

          <div>
            <label for="filter-group">{{ $t('attributes.productFilter.group') }}</label>
            <group-modal-select v-model="filters.groups" multiple />
          </div>
        </div>

        <div class="gutter-x-xs">
          <button class="btn btn-primary" type="submit">
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-check fa-w-16"><path fill="currentColor" d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z" class=""></path></svg>
            {{ $t('shared.actions.apply') }}
          </button>
          <button class="btn btn-secondary" type="button" @click="resetFilters">
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z" class=""></path></svg>
            {{ $t('shared.actions.reset') }}
          </button>
        </div>
      </form>
    </div>

    <!-- Global buttons -->
    <div class="d-flex flex-wrap justify-content-between align-items-center gutter mb-4">
      <div />

      <!-- New button -->
      <router-link :to="{ name: 'newProductFilter' }" class="btn btn-success">
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-plus fa-w-12"><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" class=""></path></svg>
        {{ $t('shared.actions.newProductFilter') }}
      </router-link>
    </div>

    <div class="position-relative">
      <!-- Table spinner -->
      <div v-if="productFiltersLoading" style="position: absolute; width: 100%; height: 100%;">
        <div style="position: sticky; top: 0; text-align: center;">
          <md-spinner md-indeterminate style="margin-top: 80px;"></md-spinner>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table" :aria-busy="productFiltersLoading">
          <thead>
            <tr>
              <th-sortable sort-key="id" class="th-shrink">{{ $t('attributes.productFilter.id') }}</th-sortable>
              <th-sortable sort-key="name">{{ $t('attributes.productFilter.name') }}</th-sortable>
              <th-sortable sort-key="group_id">{{ $t('attributes.productFilter.group') }}</th-sortable>
              <th>{{ $t('attributes.productFilter.productsCount') }}</th>
              <th class="th-shrink"></th>
            </tr>
          </thead>
          <transition name="fade" mode="out-in">
            <tbody v-if="productFiltersError">
              <tr>
                <td :colspan="colspan" class="text-center alert-danger">
                  <template v-if="productFiltersError.status === 403">
                    {{ $t('errors.unauthorized.manage.all') }}
                  </template>
                  <template v-else>
                    {{ $t('errors.internalServerError') }}
                  </template>
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="!productFiltersLoading && productFilters.length === 0">
              <tr>
                <td :colspan="colspan" class="text-center alert-warning">
                  {{ $t('shared.warnings.noProductFilter') }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="productFilter in productFilters" :key="productFilter.id">
                <th>{{ productFilter.id }}</th>
                <td>{{ productFilter.name }}</td>
                <td class="text-nowrap">
                  <router-link :to="{ name: 'group', params: { id: productFilter.group.id } }">
                    <region-flag :code="productFilter.group.region.code" />
                    {{ productFilter.group.name }}
                  </router-link>
                </td>
                <td>{{ productFilter.totalProductsCount }}</td>
                <td>
                  <b-dropdown no-caret right size="sm" variant="primary" boundary="window">
                    <template v-slot:button-content>
                      <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="ellipsis-v" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" class="svg-inline--fa fa-ellipsis-v fa-w-14"><path fill="currentColor" d="M64 208c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM16 104c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48zm0 304c0 26.5 21.5 48 48 48s48-21.5 48-48-21.5-48-48-48-48 21.5-48 48z" class=""></path></svg>
                    </template>
                    <router-link :to="{ name: 'productFilter', params: { id: productFilter.id } }" class="dropdown-item">
                      {{ $t('shared.actions.show') }}
                    </router-link>
                    <router-link :to="{ name: 'editProductFilter', params: { id: productFilter.id } }" class="dropdown-item">
                      {{ $t('shared.actions.edit') }}
                    </router-link>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </transition>
        </table>
      </div>
    </div>

    <pagination :total-rows="totalRows" :per-page="perPage" />
  </div>
</template>

<script>
import MdSpinner from '../shared/MdSpinner.vue'
import GroupModalSelect from '../shared/GroupModalSelect.vue'
import Pagination from '../shared/Pagination.vue'
import { BDropdown } from 'bootstrap-vue'
import RegionFlag from '../shared/RegionFlag.vue'
import ThSortable from '../shared/ThSortable.vue'

export default {
  components: { MdSpinner, GroupModalSelect, Pagination, BDropdown, RegionFlag, ThSortable },
  data: function() {
    return {
      productFilters: [],
      productFiltersLoading: false,
      productFiltersError: null,
      totalRows: 0,
      perPage: 20,
      filters: {
        name: null,
        groups: []
      },
      colspan: 12
    }
  },
  computed: {
    currentPage: function() {
      return parseInt(this.$route.query.page) || 1
    }
  },
  methods: {
    // Load data
    loadData: function() {
      this.productFiltersLoading = true
      this.productFiltersError = null

      const query = `query productFiltersIndex ($page: Int!, $perPage: Int!, $name: String, $groupId: Int, $groupIds: [Int!], $sort: String, $sortDirection: String) {
        productFiltersPage(page: $page, perPage: $perPage, name: $name, groupId: $groupId, groupIds: $groupIds, sort: $sort, sortDirection: $sortDirection) {
          nodesCount
          nodes {
            id
            name
            totalProductsCount
            group {
              id
              name
              region {
                id
                code
              }
            }
          }
        }
      }`

      return fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          query,
          variables: {
            page: this.currentPage,
            perPage: this.perPage,
            name: this.filters.name,
            groupId: this.$route.params.groupId ? parseInt(this.$route.params.groupId) : undefined,
            groupIds: this.filters.groups.length > 0 ? this.filters.groups.map(group => group.id) : undefined,
            sort: this.$route.query.sort,
            sortDirection: this.$route.query.sort_direction
          }
        })
      })
        .then(res => {
          return new Promise(resolve => {
            res.json().then(data => {
              resolve({ res, data })
            }).catch(() => {
              resolve({ res })
            })
          })
        }).then(({ res, data }) => {
          this.productFiltersLoading = false

          if (data.errors) {
            this.productFiltersError = { status: res.status, errors: data.errors }
          } else {
            this.totalRows = data.data.productFiltersPage.nodesCount
            this.productFilters = Object.freeze(data.data.productFiltersPage.nodes)
          }
        })
    },
    // Update url query from filters form
    updateFilters: function() {
      const query = {}

      // Filters
      if (this.filters.name) query.name = this.filters.name
      if (this.filters.groups.length > 0) query.groupIds = this.filters.groups.map(group => group.id)

      // Keep previous sort
      if (this.$route.query.sort) query.sort = this.$route.query.sort
      if (this.$route.query.sort_direction) query.sort_direction = this.$route.query.sort_direction

      this.$router.push({ query })
    },
    // Reset url query filters
    resetFilters: function() {
      this.$router.push({ query: {} })
    },
    // Update filters form from url query
    updateFiltersFromQuery: async function() {
      this.filters.name = this.$route.query.name

      if (this.$route.query.groupIds) {
        let groupIds
        if (this.$route.query.groupIds instanceof Array) {
          groupIds = this.$route.query.groupIds.map(groupId => parseInt(groupId))
        } else {
          groupIds = [parseInt(this.$route.query.groupIds)]
        }

        // Load groups from url groupIds
        const query = `query groupsFilter ($groupIds: [Int!]) {
          groups(ids: $groupIds) {
            id
            name
            active
            region {
              id
              code
            }
          }
        }`

        const res = await fetch('/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({ query, variables: { groupIds } })
        })
        const json = await res.json()
        this.filters.groups = json.data.groups
      } else {
        this.filters.groups = []
      }
    }
  },
  created: async function() {
    await this.updateFiltersFromQuery()
    await this.loadData()
  },
  watch: {
    // Update data when the route has changed
    $route: async function() {
      await this.updateFiltersFromQuery()
      await this.loadData()
    }
  }
}
</script>
